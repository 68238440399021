import React, { useEffect, useReducer } from "react";
import { Dropdown, Grid, Icon, Loader, Pagination, Table } from "semantic-ui-react";
import _ from "lodash";
import {
  LAST_CLIENT_ITEMS_PER_PAGE,
  LAST_CLIENT_SELECTED_PAGE,
  LAST_CLIENT_SORT_COLUMN,
  LAST_CLIENT_SORT_ORDER,
  localGet,
  localSet,
} from "../services/storageService";
import { useLocation } from "react-router-dom";

const itemsPerPageAlternatives = [10, 20, Infinity];

function getAvailableItemsPerPageAlternatives(totalNumberOfItems) {
  return itemsPerPageAlternatives.filter((n) => n === Infinity || n + 1 < totalNumberOfItems);
}

function sortAndPaginateReducer(state, action) {
  switch (action.type) {
    case "init": {
      const { items = [], sortColumn, sortOrder, selectedPage, itemsPerPage, sortIdFunction, storageContext } = action;
      return sortAndPaginateReducer(
        {
          items,
          selectedPage: selectedPage || 1,
          itemsPerPage: itemsPerPage || _.head(getAvailableItemsPerPageAlternatives(items.length)),
          storageContext,
        },
        {
          type: "sort",
          sortColumn,
          sortIdFunction,
          sortOrder,
          selectedPage,
        }
      );
    }
    case "page": {
      localSet(LAST_CLIENT_SELECTED_PAGE, action.selectedPage, state.storageContext);
      const selectedItems = _.slice(
        state.sortedItems,
        (action.selectedPage - 1) * state.itemsPerPage,
        action.selectedPage * state.itemsPerPage
      );
      return {
        ...state,
        selectedItems,
        selectedPage: action.selectedPage,
      };
    }
    case "sort": {
      const { sortColumn, sortIdFunction, sortOrder, selectedPage } = action;
      const newSortOrder =
        sortOrder || (sortColumn === state.sortColumn && state.sortOrder === "ascending" ? "descending" : "ascending");
      localSet(LAST_CLIENT_SORT_COLUMN, sortColumn, state.storageContext);
      localSet(LAST_CLIENT_SORT_ORDER, newSortOrder, state.storageContext);
      const sortedItems = _.orderBy(
        state.items,
        [sortIdFunction || sortColumn],
        [newSortOrder === "ascending" ? "asc" : "desc"]
      );
      return sortAndPaginateReducer(
        { ...state, sortedItems, sortColumn, sortOrder: newSortOrder },
        { type: "page", selectedPage: selectedPage || 1 }
      );
    }
    case "size": {
      const { itemsPerPage } = action;
      localSet(LAST_CLIENT_ITEMS_PER_PAGE, itemsPerPage, state.storageContext);
      return sortAndPaginateReducer({ ...state, itemsPerPage }, { type: "page", selectedPage: 1 });
    }
    default:
      throw new Error();
  }
}

/**
 * Renders a paginated, sortable table of the given input. The 'items' prop must be an array of objects containing the
 * actual data to be displayed. The 'columns' prop is an array of column definitions according to example below. If the
 * prop 'loading' is set to true, a spinner will be shown instead of the data items.
 *
 *  const items = [
 *   {
 *     id: "fea837f0-391e-467b-afab-04da82559298",
 *     version: 0,
 *     userSlug: "jane-doe-ffd1e",
 *     organizationSlug: "hejare-23b7f",
 *     lockedForEditBy: "henrik@hejare.se",
 *     name: "2020-03-16",
 *     createdAt: "2020-09-11T09:28:02.000Z",
 *     updatedAt: "2020-09-11T09:28:02.000Z"
 *     userStatus: "ACTIVE"
 *   },
 *   { ... }
 * ];
 *
 * const columns = [
 *   { name: "name", header: "Name", content: ({name})=><b>{name}</b> },
 *   { name: "createdAt", header: "Created", width: 3, onClick: (item) => console.log(item) },
 *   { name: "updatedAt", header: "Last updated", width: 3 },
 *   { name: "id", header: "ID" },
 *   { name: "secret", hidden: true }
 * ];
 *
 * The optional 'sortIdFunction' can be given for a column and used when sorting that column. This can be useful when
 * the value of the item does not reflect its sort order, for example string enums.
 */
export function DataTable({ items, columns, loading, noItemsMessage, contextPrefix = "" }) {
  const { pathname } = useLocation();

  const [state, dispatch] = useReducer(sortAndPaginateReducer, {}, () => ({}));

  useEffect(() => {
    const firstColumn = _.head(columns);
    const storageContext = `${contextPrefix}${pathname}`;
    const sortColumn = localGet(LAST_CLIENT_SORT_COLUMN, firstColumn?.name, storageContext);
    const sortOrder = localGet(LAST_CLIENT_SORT_ORDER, "ascending", storageContext);
    const selectedPage = localGet(LAST_CLIENT_SELECTED_PAGE, 1, storageContext);
    const itemsPerPage = localGet(LAST_CLIENT_ITEMS_PER_PAGE, null, storageContext);
    dispatch({
      type: "init",
      items,
      sortColumn,
      sortOrder,
      selectedPage,
      itemsPerPage,
      sortIdFunction: columns?.find((c) => c.name === sortColumn)?.sortIdFunction,
      storageContext,
    });
  }, [items, columns, pathname, contextPrefix]);

  const { selectedItems = [], selectedPage, itemsPerPage, sortColumn, sortOrder } = state;

  const tableHeaders = (
    <Table.Row>
      {columns
        .filter((column) => !column.hidden)
        .map((column, index) => (
          <Table.HeaderCell
            key={index}
            width={column.width}
            onClick={() =>
              column.sortable === false
                ? null
                : dispatch({
                    type: "sort",
                    sortColumn: column.name,
                    sortIdFunction: column.sortIdFunction,
                  })
            }
            sorted={sortColumn === column.name ? sortOrder : null}
            content={column.header}
          />
        ))}
    </Table.Row>
  );

  let tableBody;
  if (loading) {
    tableBody = (
      <Table.Row>
        <Table.Cell colSpan={columns.length} textAlign="center">
          <Loader active inline />
        </Table.Cell>
      </Table.Row>
    );
  } else if (selectedItems.length === 0) {
    tableBody = (
      <Table.Row>
        <Table.Cell colSpan={columns.length} textAlign="center">
          No items
        </Table.Cell>
      </Table.Row>
    );
  } else {
    tableBody = selectedItems.map((item, index) => {
      const rowContent = columns
        .filter((column) => !column.hidden)
        .map((column, i) => (
          <Table.Cell
            key={i}
            onClick={() => (column.onClick ? column.onClick(item) : null)}
            style={{ cursor: column.onClick ? "pointer" : null }}
            content={column.content ? column.content(item) : item[column.name]}
          />
        ));
      return <Table.Row key={index}>{rowContent}</Table.Row>;
    });
  }

  const totalNumberOfItems = items?.length || 0;
  const itemsPerPageOptions = getAvailableItemsPerPageAlternatives(totalNumberOfItems).map((n) => ({
    key: n,
    value: n,
    text: n === Infinity ? "All" : `Show ${n} rows per page`,
  }));
  const itemsPerPageSelector =
    itemsPerPageOptions.length > 1 ? (
      <Dropdown
        selection
        fluid
        value={itemsPerPage}
        onChange={(_event, selection) => dispatch({ type: "size", itemsPerPage: selection.value })}
        options={itemsPerPageOptions}
      />
    ) : null;

  const totalPages = Math.ceil((items?.length || itemsPerPage) / itemsPerPage);
  const pagination =
    totalPages > 1 ? (
      <Pagination
        activePage={selectedPage}
        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
        firstItem={false}
        lastItem={false}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={totalPages}
        onPageChange={(event, newPagination) => dispatch({ type: "page", selectedPage: newPagination.activePage })}
      />
    ) : null;
  const paginationControls = (
    <Grid>
      <Grid.Column width={4} />
      <Grid.Column width={8} textAlign="center">
        {pagination}
      </Grid.Column>
      <Grid.Column width={4}>{itemsPerPageSelector}</Grid.Column>
    </Grid>
  );

  return (
    <>
      <Table selectable fixed sortable>
        <Table.Header content={tableHeaders} />
        <Table.Body>{tableBody}</Table.Body>
      </Table>
      {paginationControls}
    </>
  );
}
