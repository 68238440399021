import React, { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteOrganization,
  getOrganizationInvoiceFoundations,
  updateOrganization,
} from "../services/momangAdminService";
import { Button, Loader, Message, Segment, Table } from "semantic-ui-react";
import { EditOrganization } from "../components/EditOrganization";
import { useAuthenticatedUserContext } from "../contexts/authenticatedUserContext";
import { useQuery } from "react-query";
import { createOrganizationFineTuneModel, getProfileAutomatorOrganization } from "../services/profileAutomatorService";
import { AiUsageChart } from "../components/AiUsageChart";
import { getAiUsagePerFunction } from "../services/analyticsService";

export function Organization() {
  const { organizationSlug } = useParams();
  const navigate = useNavigate();
  const { authenticatedUser, ghost, resurrect } = useAuthenticatedUserContext();
  const [invoiceData, setInvoiceData] = useState();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const {
    isLoading: isLoadingProfileAutomator,
    error: errorProfileAutomator,
    data: profileAutomatorOrganization,
  } = useQuery(["profile-automator-organization", organizationSlug], () =>
    getProfileAutomatorOrganization(organizationSlug)
  );

  const updateInvoiceData = useCallback(async () => {
    if (organizationSlug) {
      setLoading(true);
      const data = await getOrganizationInvoiceFoundations(organizationSlug);
      setInvoiceData(data);
      setLoading(false);
    }
  }, [organizationSlug]);

  useEffect(() => {
    updateInvoiceData();
  }, [organizationSlug, updateInvoiceData]);

  if (!invoiceData) return <Loader active />;

  return (
    <Segment basic disabled={loading || updating}>
      <h1>{invoiceData.organization?.name}</h1>
      <h2>Ghost</h2>
      {authenticatedUser?.roles?.includes("ghost") && authenticatedUser?.organizationSlug === organizationSlug ? (
        <>
          <p>
            You are a ghost in <i>{invoiceData.organization?.name}</i>.
          </p>
          <Button content="Resurrect" icon="lightning" onClick={() => resurrect()} />
        </>
      ) : (
        <>
          <p>
            Join this organization as a ghost user. When you log in to Momang, you will belong to{" "}
            <i>{invoiceData.organization?.name}</i>, but you will not be visible in the lists of users and consultants.
          </p>
          <Button
            icon="snapchat ghost"
            onClick={() => ghost(invoiceData.organization?.slug)}
            content={`Join ${invoiceData.organization?.name} as ghost`}
          />
        </>
      )}

      <h2>Update</h2>
      <EditOrganization
        organization={invoiceData.organization}
        onChange={async (organization) => {
          setUpdating(true);
          await updateOrganization(organization);
          setUpdating(false);
          await updateInvoiceData();
        }}
      />

      <h2>AI usage</h2>
      <AiUsageChart
        organizationSlug={organizationSlug}
        fetcher={getAiUsagePerFunction}
        fetcherDependencies={["ai-usage-per-function", organizationSlug]}
        showLegend
      />

      <h2>Invoice</h2>
      <p>
        A user is considered billable in a period as long as it is (a) created before the period starts, (b) not deleted
        before the period starts, and (c) has at least one of the roles 'admin', 'sales' or 'consultant'.
      </p>
      <p>Note that subcontractors are not included.</p>
      <p>
        Note that we don't store historical information on <i>when</i> a user changes role, so the previously periods
        will also change if a user is changed from a subcontractor to a consultant, for example, this will change data
        for previous periods. Similarly, changes in price per user will also affect previous periods.
      </p>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Period</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Billable users</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Discount</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoiceData.invoiceFoundations.map((invoiceFoundation, i) => {
            const periodStart = DateTime.fromISO(invoiceFoundation.periodStart);
            const currencyFormatter = new Intl.NumberFormat("sv", {
              style: "currency",
              currency: invoiceFoundation.invoiceCurrency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true,
            });
            return (
              <Table.Row key={i}>
                <Table.Cell>{periodStart.toFormat("MMMM yyyy")}</Table.Cell>
                <Table.Cell textAlign="right">{invoiceFoundation.billableUsers}</Table.Cell>
                <Table.Cell textAlign="right">{currencyFormatter.format(invoiceFoundation.invoiceAmount)}</Table.Cell>
                <Table.Cell textAlign="right">
                  {currencyFormatter.format(-invoiceFoundation.invoiceDiscount)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <strong>{currencyFormatter.format(invoiceFoundation.invoiceTotal)}</strong>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <h2>Organization data</h2>
      <Segment>
        <pre>{JSON.stringify(invoiceData.organization, null, 2)}</pre>
      </Segment>

      <h2>Profile Automator data</h2>
      <p>
        A fine-tune model uses the content already entered into Momang as training data for suggesting new project
        descriptions. This should give more accurate and similar texts to what the user expects. The more training data,
        the better the results. We require 100 existing project descriptions in the consultants' main profiles in order
        to start the fine tune training.
      </p>
      <Button content="Create a fine-tune model" onClick={() => createOrganizationFineTuneModel(organizationSlug)} />
      {isLoadingProfileAutomator ? (
        <Loader active inline="centered" content="Loading profile automator data..." />
      ) : (
        <Segment>
          <pre>{JSON.stringify(profileAutomatorOrganization, null, 2)}</pre>
          <Message error content={errorProfileAutomator?.message} hidden={!errorProfileAutomator} />
        </Segment>
      )}

      <h2>Danger Zone</h2>
      <p>
        You can delete the organization here. It will also delete all users and related information such as events,
        profiles as well as CRM information.
      </p>
      <Button
        content={`Delete ${invoiceData.organization?.name}`}
        icon="trash"
        color="red"
        onClick={async () => {
          if (window.confirm(`Are you sure you want to delete ${invoiceData.organization?.name}?`)) {
            await deleteOrganization(invoiceData.organization?.slug);
            navigate("/organizations");
          }
        }}
      />
    </Segment>
  );
}
