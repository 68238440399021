import { useQuery } from "react-query";
import { getContentSchemas } from "../services/momangAdminService";
import { Label, Loader, Message } from "semantic-ui-react";
import React from "react";
import { DataTable } from "../components/DataTable";
import _ from "lodash";
import { getProfileAutomatorFeatures } from "../services/profileAutomatorService";

export function Templates() {
  const contentSchemas = useQuery(["contentSchemas"], getContentSchemas);
  const profileAutomatorFeatures = useQuery(["profile-automator-features"], getProfileAutomatorFeatures);

  if (contentSchemas.isLoading || profileAutomatorFeatures.isLoading)
    return <Loader active inline="centered" content="Fetching templates" />;
  if (contentSchemas.error) return <Message visible error content={contentSchemas.error.message} />;
  if (profileAutomatorFeatures.error) return <Message visible error content={profileAutomatorFeatures.error.message} />;

  const columns = [
    { name: "name", header: "Name", content: ({ name, slug }) => `${name} (${slug})` },
    {
      name: "pdfEngine",
      header: "PDF Engine",
      content: ({ renderTemplates }) =>
        _.find(renderTemplates, (rt) => rt.renderEngine === "ott") ? (
          <Label basic color="green" content="OTT" icon="file word outline" />
        ) : (
          <Label basic color="grey" content="Web" icon="globe" />
        ),
    },
    {
      name: "profileSuggestSupport",
      header: "Profile suggest support via LinkedIn and OpenAI",
      content: ({ slug }) =>
        _.find(profileAutomatorFeatures.data.supportedContentSchemas, (paf) => paf === slug) ? (
          <Label basic color="green" icon="check" content="Yes" />
        ) : (
          <Label basic color="grey" icon="times" content="No" />
        ),
    },
  ];

  return (
    <>
      <h2>Templates</h2>
      <DataTable items={contentSchemas.data} columns={columns} fixed={false} />
    </>
  );
}
