import { Grid } from "semantic-ui-react";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import { PageNotFound } from "../pages/PageNotFound";
import { Organizations } from "../pages/Organizations";
import { Organization } from "../pages/Organization";
import { MainMenu } from "./MainMenu";
import { QueryClient, QueryClientProvider } from "react-query";
import { Users } from "../pages/Users";
import { User } from "../pages/User";
import { AuthenticatedUserContextProvider } from "../contexts/authenticatedUserContext";
import { OpenAi } from "../pages/OpenAi";
import { OpenAiFineTune } from "../pages/OpenAiFineTune";
import { Templates } from "../pages/Templates";

const queryClient = new QueryClient();

export function Scaffold() {
  return (
    <AuthenticatedUserContextProvider>
      <QueryClientProvider client={queryClient}>
        <Grid style={{ minHeight: "100vh" }}>
          <Grid.Row>
            <Grid.Column width={3}>
              <MainMenu />
            </Grid.Column>
            <Grid.Column width={13}>
              <Grid padded>
                <Grid.Column>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="organizations/:organizationSlug" element={<Organization />} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/:userSlug" element={<User />} />
                    <Route path="templates" element={<Templates />} />
                    <Route path="openai" element={<OpenAi />} />
                    <Route path="openai/fine-tunes/:fineTuneId" element={<OpenAiFineTune />} />
                    <Route element={<PageNotFound />} />
                  </Routes>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </QueryClientProvider>
    </AuthenticatedUserContextProvider>
  );
}
