import { getJwt } from "./auth";
import axios from "axios";

let axiosClient;

async function init() {
  const jwt = await getJwt();
  const headers = { Authorization: jwt };
  axiosClient = axios.create({
    baseURL: process.env.REACT_APP_PROFILE_AUTOMATOR_ENDPOINT,
    headers,
  });
}

export async function createOrganizationFineTuneModel(organizationSlug) {
  await init();
  const result = await axiosClient.post(`/admin/organizations/${organizationSlug}/fine-tunes`);
  return result.data;
}

export async function getFineTunes() {
  await init();
  const result = await axiosClient.get("/admin/fine-tunes");
  return result.data;
}

export async function getFineTune(fineTuneId) {
  await init();
  const result = await axiosClient.get(`/admin/fine-tunes/${fineTuneId}`);
  return result.data;
}

export async function cancelFineTune(fineTuneId) {
  await init();
  const result = await axiosClient.post(`/admin/fine-tunes/${fineTuneId}/cancel`);
  return result.data;
}

export async function deleteFineTuneModel(fineTuneModelId) {
  await init();
  await axiosClient.delete(`/admin/fine-tune-models/${fineTuneModelId}`);
}

export async function getProfileAutomatorOrganization(organizationSlug) {
  await init();
  const result = await axiosClient.get(`/admin/organizations/${organizationSlug}`);
  return result.data;
}

export async function getProfileAutomatorFeatures() {
  await init();
  const result = await axiosClient.get(`/admin/features`);
  return result.data;
}
