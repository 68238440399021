import React from "react";
import { Icon, Label } from "semantic-ui-react";

//pending, running, succeeded, or failed

const statusMap = {
  pending: { color: "yellow", icon: "hourglass half" },
  running: { color: "blue", icon: "sync", rotating: true },
  succeeded: { color: "green", icon: "check" },
  cancelled: { color: "grey", icon: "times" },
  failed: { color: "red", icon: "times" },
};

export function FineTuneStatus({ status }) {
  const { color, icon, rotating } = statusMap[status] || { color: "grey", icon: "question" };
  return <Label icon={<Icon name={icon} loading={rotating} />} color={color} content={status} />;
}
