import React from "react";
import { DataTable } from "../components/DataTable";
import { Link } from "react-router-dom";
import { getOrganizations } from "../services/momangAdminService";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { Message } from "semantic-ui-react";

export const Organizations = () => {
  const { isLoading, error, data: organizations } = useQuery("organizations", getOrganizations);

  const columns = [
    { name: "name", header: "Name", content: ({ name, slug }) => <Link to={`/organizations/${slug}`}>{name}</Link> },
    { name: "plan", header: "Plan" },
    {
      name: "numberOfUsers",
      header: "Number of users",
      content: ({ numberOfUsers, slug }) => <Link to={`/users?organizationSlug=${slug}`} children={numberOfUsers} />,
    },
    {
      name: "createdAt",
      header: "Created At",
      content: ({ createdAt }) => DateTime.fromISO(createdAt).toFormat("yyyy-MM-dd"),
    },
    {
      name: "updatedAt",
      header: "Last updated",
      content: ({ updatedAt }) => DateTime.fromISO(updatedAt).toFormat("yyyy-MM-dd"),
    },
  ];

  if (error) return <Message error visible content={error.message} />;

  return (
    <>
      <h2>Organizations</h2>
      <DataTable items={organizations} columns={columns} loading={isLoading} fixed={false} />
    </>
  );
};
