import { useQuery } from "react-query";
import { Loader, Message } from "semantic-ui-react";
import { DataTable } from "../components/DataTable";
import { getFineTunes } from "../services/profileAutomatorService";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { FineTuneStatus } from "../components/openai/FineTuneStatus";
import { AiUsageChart } from "../components/AiUsageChart";
import { getAiUsagePerOrganization } from "../services/analyticsService";
import React from "react";

export function OpenAi() {
  const { isLoading, error, data } = useQuery(["fine-tune-models"], () => getFineTunes());

  const fineTunes = data?.data || [];
  const columns = [
    {
      name: "id",
      header: "ID",
      content: (fineTune) => <Link to={`fine-tunes/${fineTune.id}`} children={fineTune.id} />,
    },
    {
      name: "training_files",
      header: "Training files",
      content: (fineTune) => fineTune.training_files.map?.((file) => file.filename).join(", "),
    },
    { name: "fine_tuned_model", header: "Model" },
    {
      name: "created_at",
      header: "Created",
      content: (fineTune) => DateTime.fromSeconds(fineTune.created_at).toFormat("yyyy-MM-dd HH:mm:ss"),
      width: 2,
    },
    {
      name: "status",
      header: "Status",
      content: (fineTune) => <FineTuneStatus status={fineTune.status} />,
      width: 2,
    },
  ];

  return (
    <>
      <h2>AI Usage</h2>
      <AiUsageChart fetcher={getAiUsagePerOrganization} fetcherDependencies={["ai-usage-per-organization"]} />
      <h2>OpenAI Fine Tune Models</h2>
      {isLoading && <Loader active inline="centered" content="Fetching fine tunes" />}
      {error && <Message visible error content={error.message} />}
      {!isLoading && !error && <DataTable items={fineTunes} columns={columns} />}
    </>
  );
}
