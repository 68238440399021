import { useQuery } from "react-query";
import { getUsers } from "../services/momangAdminService";
import { Loader, Message } from "semantic-ui-react";
import { DataTable } from "../components/DataTable";
import { Link, useSearchParams } from "react-router-dom";

export function Users() {
  const [search] = useSearchParams();
  const organizationSlug = search.get("organizationSlug");
  const { isLoading, error, data: users } = useQuery(["users", organizationSlug], () => getUsers(organizationSlug));

  if (isLoading) return <Loader active inline="centered" content="Fetching users" />;
  if (error) return <Message visible error content={error.message} />;

  const columns = [
    { name: "name", header: "Name", content: ({ name, slug }) => <Link to={`/users/${slug}`} children={name} /> },
    { name: "email", header: "Email" },
    {
      name: "organizationSlug",
      header: "Organization",
      content: ({ organizationSlug }) => <Link to={`/organizations/${organizationSlug}`} children={organizationSlug} />,
    },
    { name: "userStatus", header: "Status" },
  ];

  return (
    <>
      <h2>Users</h2>
      <DataTable items={users} columns={columns} />
    </>
  );
}
