import { Auth } from "aws-amplify";
import axios from "axios";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

export async function getAwsConfig() {
  const { default: awsConfig } = await import(`../aws-exports-${process.env.REACT_APP_STAGE}`);
  return awsConfig;
}

export async function getJwt() {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export async function startAuthSessionFromCode(code) {
  const awsConfig = await getAwsConfig();
  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: awsConfig.aws_user_pools_web_client_id,
    redirect_uri: `${process.env.REACT_APP_BASE_URL}/oauth2/authorization-code`,
    code,
  });
  const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URL}/oauth2/token`, params);
  const sessionData = {
    IdToken: new CognitoIdToken({ IdToken: data.id_token }),
    AccessToken: new CognitoAccessToken({ AccessToken: data.access_token }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: data.refresh_token }),
  };
  const session = new CognitoUserSession(sessionData);
  const cognitoUser = new CognitoUser({
    Username: sessionData.AccessToken.payload.username,
    Pool: new CognitoUserPool({
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id,
    }),
  });
  cognitoUser.setSignInUserSession(session);
}
