import { Menu } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function MainMenu() {
  const { pathname } = useLocation();
  const { signOut, user } = useAuthenticator();

  return (
    <Menu vertical inverted attached fluid style={{ minHeight: "100%" }}>
      <Menu.Item as={Link} to="/" active={pathname === "/"} icon="home" content="Home" />
      <Menu.Item
        as={Link}
        to="/organizations"
        active={pathname === "/organizations"}
        icon="building"
        content="Organizations"
      />
      <Menu.Item as={Link} to="/users" active={pathname === "/users"} icon="users" content="Users" />
      <Menu.Item
        as={Link}
        to="/templates"
        active={pathname === "/templates"}
        icon="newspaper outline"
        content="Templates"
      />
      <Menu.Item as={Link} to="/openai" active={pathname === "/openai"} icon="wizard" content="OpenAI" />
      <Menu.Item
        as="a"
        onClick={async () => {
          await signOut();
          window.location.reload();
        }}
        icon="sign-out"
        content={`Sign out ${user?.attributes?.email}`}
      />
    </Menu>
  );
}
