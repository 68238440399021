import { getJwt } from "./auth";
import axios from "axios";

let axiosClient;

async function init() {
  const jwt = await getJwt();
  const headers = { Authorization: jwt };
  axiosClient = axios.create({
    baseURL: process.env.REACT_APP_MOMANG_ADMIN_BACKEND_ENDPOINT,
    headers,
  });
}

export async function getAuthenticatedUser() {
  await init();
  const result = await axiosClient.get("/users/authenticated");
  return result.data;
}

export async function getOrganizationInvoiceFoundations(organizationSlug) {
  await init();
  const result = await axiosClient.get(`/organizations/${organizationSlug}/invoice-foundations`);
  return result.data;
}

export async function getOrganizations() {
  await init();
  const result = await axiosClient.get("/organizations");
  return result.data;
}

export async function deleteOrganization(organizationSlug) {
  await init();
  await axiosClient.delete(`/organizations/${organizationSlug}`);
}

export async function updateOrganization(organization) {
  await init();
  await axiosClient.put(`/organizations/${organization.slug}`, organization);
}

export async function ghostOrganization(organizationSlug) {
  await init();
  const result = await axiosClient.put(`/organizations/${organizationSlug}/ghost`);
  return result.data;
}

export async function resurrect() {
  await init();
  const result = await axiosClient.put("/resurrect");
  return result.data;
}

export async function getUsers(organizationSlug) {
  await init();
  const result = await axiosClient.get("/users", { params: { organizationSlug } });
  return result.data;
}

export async function getUser(userSlug) {
  await init();
  const result = await axiosClient.get(`/users/${userSlug}`);
  return result.data;
}

export async function updateUser(userSlug, { password }) {
  await init();
  const result = await axiosClient.put(`/users/${userSlug}`, { password });
  return result.data;
}

export async function getContentSchemas() {
  await init();
  const result = await axiosClient.get("/content-schemas");
  return result.data;
}
