import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { startAuthSessionFromCode } from "../services/auth";
import { Auth } from "aws-amplify";
import { Container, Loader, Message, Segment } from "semantic-ui-react";

export function AuthorizationCode() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [alreadySignedIn, setAlreadySignedIn] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    Auth.currentSession()
      .then(() => navigate("/"))
      .catch(() => setAlreadySignedIn(false));
  }, [navigate]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    if (urlSearchParams.has("code") && alreadySignedIn === false) {
      startAuthSessionFromCode(urlSearchParams.get("code"))
        .then(() => navigate("/"))
        .catch((error) => setError(error.message));
    }
  }, [search, alreadySignedIn, navigate]);

  return (
    <Container text>
      <Segment textAlign="center">
        <h1>Getting things in order...</h1>
        <Loader active={!error} inline />
        <Message error hidden={!error} content={<pre>{error}</pre>} />
      </Segment>
    </Container>
  );
}
