import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getAuthenticatedUser, ghostOrganization, resurrect as masResurrect } from "../services/momangAdminService";

const initialState = {};

const AuthenticatedUserContext = createContext(initialState);

export function useAuthenticatedUserContext() {
  return useContext(AuthenticatedUserContext);
}

export function AuthenticatedUserContextProvider({ children }) {
  const [authenticatedUser, setAuthenticatedUser] = useState();

  useEffect(() => {
    getAuthenticatedUser().then(setAuthenticatedUser);
  }, []);

  const ghost = useCallback((organizationSlug) => {
    ghostOrganization(organizationSlug).then(setAuthenticatedUser);
  }, []);

  const resurrect = useCallback(() => {
    masResurrect().then(setAuthenticatedUser);
  }, []);

  const value = useMemo(
    () => ({
      authenticatedUser,
      ghost,
      resurrect,
    }),
    [authenticatedUser, ghost, resurrect]
  );
  return <AuthenticatedUserContext.Provider value={value} children={children} />;
}
