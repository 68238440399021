import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useQuery } from "react-query";
import { Checkbox, Loader, Message } from "semantic-ui-react";
import { DateTime } from "luxon";

export function AiUsageChart({ organizationSlug, fetcher, fetcherDependencies, showLegend }) {
  const [groupBy, setGroupBy] = useState("day"); // ["day", "month"]
  const {
    isLoading: isLoadingAiUsage,
    error: errorAiUsage,
    data: aiUsage,
  } = useQuery({
    queryKey: [...fetcherDependencies, groupBy],
    queryFn: () => fetcher({ organizationSlug, groupBy }),
    enabled: !!fetcher && !!fetcherDependencies,
  });

  if (isLoadingAiUsage) return <Loader active inline content="Loading AI usage..." />;
  if (errorAiUsage) return <Message error>Could not load AI usage: {errorAiUsage.message}</Message>;

  function formatIndexValue(indexValue) {
    return groupBy === "day"
      ? DateTime.fromISO(indexValue).toFormat("yyyy-MM-dd")
      : DateTime.fromISO(indexValue).toFormat("yyyy-MM");
  }

  const legendWidth = 170;
  return (
    <>
      <div style={{ width: "100%", height: "200px" }}>
        <ResponsiveBar
          data={aiUsage?.data}
          keys={aiUsage?.keys}
          indexBy="date"
          groupMode="stacked"
          margin={{ top: 10, right: 10 + (showLegend ? legendWidth : 0), bottom: 30, left: 60 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            format: formatIndexValue,
          }}
          axisLeft={{
            legend: "Cost, USD",
            legendPosition: "middle",
            legendOffset: -50,
            tickSize: 5,
            tickPadding: 5,
            format: ".2f",
          }}
          labelSkipHeight={10}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.8]],
          }}
          valueFormat={(value) => `${value.toFixed(2)} USD`}
          legends={
            showLegend
              ? [
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    translateX: legendWidth,
                    translateY: 0,
                    itemsSpacing: 3,
                    itemWidth: legendWidth * 0.9,
                    itemHeight: 20,
                    symbolSize: 15,
                    toggleSerie: true,
                    effects: [{ on: "hover", style: { itemBackground: "hsl(0, 0%, 95%)" } }],
                  },
                ]
              : []
          }
          tooltipLabel={({ id, indexValue }) => `${id}, ${formatIndexValue(indexValue)}`}
        />
      </div>
      <Checkbox
        radio
        name="groupBy"
        checked={groupBy === "day"}
        onClick={() => setGroupBy("day")}
        label={"Group by day"}
      />
      <br />
      <Checkbox
        radio
        name="groupBy"
        checked={groupBy === "month"}
        onClick={() => setGroupBy("month")}
        label={"Group by month"}
      />
    </>
  );
}
