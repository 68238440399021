import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, Image, Loader, Message, Segment } from "semantic-ui-react";
import { getUser, updateUser } from "../services/momangAdminService";
import password from "secure-random-password";
import { useAuthenticatedUserContext } from "../contexts/authenticatedUserContext";

export function User() {
  const { userSlug } = useParams();
  const { isLoading, error, data: user } = useQuery(["user", userSlug], () => getUser(userSlug));
  const [newPassword, setNewPassword] = useState();
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const { authenticatedUser } = useAuthenticatedUserContext();

  if (isLoading) return <Loader active inline="centered" content="Fetching user" />;
  if (error) return <Message visible error content={error.message} />;

  const firstName = user?.name?.split(" ")[0];
  return (
    <>
      <h2>{user.name}</h2>
      <Image src={user.pictureUrl} size="small" avatar />

      {user?.cognitoUserId && (
        <Segment>
          <h3>New password</h3>
          <p>
            Use this feature to generate and set a new, random password for the user. Note that the current password
            will be lost and that the user cannot sign in until she gets the new password.
          </p>
          <Button
            color="red"
            content="Generate and set new password"
            loading={isUpdatingPassword}
            disabled={!!newPassword || isUpdatingPassword}
            onClick={async () => {
              try {
                setIsUpdatingPassword(true);
                const generatedPassword = password.randomPassword({
                  characters: [
                    { characters: password.upper, exactly: 1 },
                    { characters: password.symbols, exactly: 1 },
                    password.digits,
                    password.lower,
                  ],
                });
                await updateUser(userSlug, { password: generatedPassword });
                setNewPassword(generatedPassword);
              } finally {
                setIsUpdatingPassword(false);
              }
            }}
          />

          {newPassword && (
            <>
              <h4>Suggested communication</h4>
              <p>
                The password <code style={{ fontWeight: "bold" }}>{newPassword}</code> has been set. Feel free to use
                the template below to let the user know.
              </p>
              <Segment secondary>
                <p>Hello, {firstName}!</p>
                <p>
                  I understand that you've had issues receiving password emails from Momang. Sorry about that! I've set
                  a new password for you. It is
                </p>
                <p>
                  <pre>{newPassword}</pre>
                </p>
                <p>
                  Sign in as usual with this password and go to Personal Settings at{" "}
                  <a href="https://app.momang.io/settings/personal">https://app.momang.io/settings/personal</a> to
                  change it to something only you know.
                </p>
                <p>Don't hesitate to contact me again if you have any other questions.</p>
                <p>
                  Take care,
                  <br />
                  {authenticatedUser.name}
                </p>
              </Segment>
            </>
          )}
        </Segment>
      )}

      <Segment>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </Segment>
    </>
  );
}
