import React from "react";
import { createRoot } from "react-dom/client";
import { MomangAdmin } from "./MomangAdmin";
import "semantic-ui-less/semantic.less";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MomangAdmin />
  </React.StrictMode>
);
