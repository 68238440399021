import slugify from "slugify";

const INFINITY_STRING = "-infinity-";

export const LAST_CLIENT_SORT_COLUMN = "last-client-sort-column";
export const LAST_CLIENT_SORT_ORDER = "last-client-sort-order";
export const LAST_CLIENT_SELECTED_PAGE = "last-client-selected-page";
export const LAST_CLIENT_ITEMS_PER_PAGE = "last-client-items-per-page";

function calcKey(key, context) {
  slugify.extend({ "/": "-" });
  return `momang-${key}${slugify(context || "")}`;
}

// Serializes an object to a string to be put in localstorage. Specifically handles the infinity value.
// Exported for testability.
export function serializeInternal(objectValue) {
  return JSON.stringify(objectValue, (key, value) => {
    if (value === Infinity) return INFINITY_STRING;
    return value;
  });
}

// Deserializes value stored in localstorage. Specifically, it handles infinity value and dates properly.
// Exported for testability.
export function deserializeInternal(stringValue) {
  try {
    return JSON.parse(stringValue, (key, value) => {
      if (typeof value === "string") {
        if (value === INFINITY_STRING) return Infinity;
        const potentialDate = new Date(value);
        if (JSON.stringify(potentialDate) === `"${value}"`) return potentialDate;
      }
      return value;
    });
  } catch (_e) {
    // Handles the case where we have a value stored by the previous version where strings were not stored with quotes
    // and therefore is not parsable with JSON.parse().
    if (stringValue === INFINITY_STRING) return Infinity;
    return stringValue;
  }
}

export function localSet(key, value, context = null) {
  try {
    localStorage.setItem(calcKey(key, context), serializeInternal(value));
  } catch (error) {
    console.error("Could not set value in localstorage.", error, key, value, context);
  }
}

export function localGet(key, defaultValue = null, context = null) {
  try {
    const stringValue = localStorage.getItem(calcKey(key, context));
    if (stringValue === null) return defaultValue;
    return deserializeInternal(stringValue);
  } catch (error) {
    console.error("Could not get value from localstorage.", error, key, context);
  }
  return defaultValue;
}
