import React, { useEffect, useState } from "react";
import { Authenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { Scaffold } from "./components/Scaffold";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthorizationCode } from "./components/AuthorizationCode";
import { getAwsConfig } from "./services/auth";

export function MomangAdmin() {
  const [awsIsConfigured, setAwsIsConfigured] = useState(false);

  useEffect(() => {
    getAwsConfig()
      .then((awsConfig) => Amplify.configure(awsConfig))
      .then(() => setAwsIsConfigured(true));
  }, []);

  if (!awsIsConfigured) return null;

  return (
    <Router>
      <Routes>
        <Route path="/oauth2/authorization-code" element={<AuthorizationCode />} />
        <Route
          path="*"
          element={
            <Authenticator
              usernameAlias="email"
              hideSignUp
              variation="modal"
              components={{
                Header: () => (
                  <View>
                    <input
                      type="button"
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth2/authorize?identity_provider=azure-ad&redirect_uri=${process.env.REACT_APP_BASE_URL}/oauth2/authorization-code&response_type=CODE&client_id=6o3c15m64k12o2qem5nk5otihh&scope=email openid phone`)
                      }
                      value="azure-ad"
                      className="btn btn-info idpButton-customizable"
                      aria-label="azure-ad"
                    />
                    <input
                      type="button"
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_BASE_URL}/oauth2/authorization-code&response_type=CODE&client_id=6o3c15m64k12o2qem5nk5otihh&scope=email openid phone profile`)
                      }
                      value="Google"
                      className="btn btn-info idpButton-customizable"
                      aria-label="Google"
                    />

                    <p></p>
                  </View>
                ),
              }}
              children={Scaffold}
            />
          }
        />
      </Routes>
    </Router>
  );
}
