import React from "react";
import { Button, Segment } from "semantic-ui-react";
import { useAuthenticatedUserContext } from "../contexts/authenticatedUserContext";

export function Dashboard() {
  const { authenticatedUser, resurrect } = useAuthenticatedUserContext();

  const ghostInfo = authenticatedUser?.roles?.includes("ghost") ? (
    <>
      <p>
        You are a ghost in <i>{authenticatedUser.organizationSlug}</i>. Resurrect yourself to go back to your home
        organization.
      </p>
      <Button content="Resurrect" icon="lightning" onClick={() => resurrect()} />
    </>
  ) : null;

  return (
    <>
      <h2>Welcome, {authenticatedUser?.name}</h2>
      {ghostInfo}
      <Segment>
        <pre>{JSON.stringify(authenticatedUser, null, 2)}</pre>
      </Segment>
    </>
  );
}
