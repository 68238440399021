import _ from "lodash";
import { useQuery } from "react-query";
import { Button, Loader, Message, Segment } from "semantic-ui-react";
import { DataTable } from "../components/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import { cancelFineTune, deleteFineTuneModel, getFineTune } from "../services/profileAutomatorService";
import { DateTime } from "luxon";
import { FineTuneStatus } from "../components/openai/FineTuneStatus";

export function OpenAiFineTune() {
  const { fineTuneId } = useParams();
  const {
    isLoading,
    error,
    data: fineTune,
    refetch,
  } = useQuery(["fine-tune", fineTuneId], () => getFineTune(fineTuneId));
  const navigate = useNavigate();

  if (isLoading) return <Loader active inline="centered" content="Fetching fine tune" />;
  if (error) return <Message visible error content={error.message} />;

  const columns = [
    {
      name: "created_at",
      header: "Created",
      content: (ft) => DateTime.fromSeconds(ft.created_at).toFormat("yyyy-MM-dd HH:mm:ss"),
      width: 2,
    },
    { name: "message", header: "Message", sortable: false },
  ];

  const { status, fine_tuned_model } = fineTune;
  return (
    <>
      <h2>OpenAI Fine Tune</h2>
      <p>
        <FineTuneStatus status={status} />
      </p>
      {(status === "pending" || status === "running") && (
        <Button
          content="Cancel"
          onClick={() => {
            if (window.confirm("Are you sure you wish to cancel the fine tune job?"))
              cancelFineTune(fineTuneId).then(() => refetch());
          }}
        />
      )}
      {fine_tuned_model && (
        <Button
          content="Delete"
          color="red"
          icon="trash"
          onClick={() => {
            if (window.confirm("Are you sure you wish to delete the fine tune model?"))
              deleteFineTuneModel(fine_tuned_model).then(() => navigate("/openai"));
          }}
        />
      )}

      <h3>Events</h3>
      <DataTable items={fineTune.events} columns={columns} />
      <h3>Info</h3>
      <Segment>
        <pre>{JSON.stringify(_.omit(fineTune, "events"), null, 2)}</pre>
      </Segment>
    </>
  );
}
