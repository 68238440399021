import { getJwt } from "./auth";
import axios from "axios";

let axiosClient;

async function init() {
  const jwt = await getJwt();
  const headers = { Authorization: jwt };
  axiosClient = axios.create({
    baseURL: process.env.REACT_APP_ANALYTICS_BACKEND_ENDPOINT,
    headers,
  });
}

export async function getAiUsagePerFunction({ organizationSlug, groupBy }) {
  await init();
  const result = await axiosClient.get("/admin/queries/ai-usage-per-function", {
    params: { organizationSlug, groupBy },
  });
  return result.data;
}

export async function getAiUsagePerOrganization({ groupBy }) {
  await init();
  const result = await axiosClient.get("/admin/queries/ai-usage-per-organization", {
    params: { groupBy },
  });
  return result.data;
}
