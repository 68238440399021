import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form } from "semantic-ui-react";

const plans = {
  essential: ["users.base", "profiles.base"],
  pro: ["users.base", "profiles.*"],
  premium: [".*"],
  custom: [],
};

const planOptions = Object.keys(plans).map((plan) => ({ key: plan, value: plan, text: _.upperFirst(plan) }));

export function EditOrganization({ organization, onChange }) {
  const [localOrganization, setLocalOrganization] = useState({});

  useEffect(() => {
    setLocalOrganization(_.cloneDeep(organization));
  }, [organization]);

  function onLocalChange(_event, { id, value }) {
    const featureAccessList = id === "plan" ? plans[value] : localOrganization.featureAccessList;
    setLocalOrganization({ ...localOrganization, featureAccessList, [id]: value });
  }

  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          label="Plan"
          id="plan"
          options={planOptions}
          value={localOrganization.plan}
          selection
          onChange={onLocalChange}
        />
        <Form.Input
          label="Price per user and month"
          id="pricePerUserMonth"
          value={localOrganization.pricePerUserMonth}
          onChange={onLocalChange}
        />
        <Form.Input
          label="Discount per user and month"
          id="discountPerUserMonth"
          value={localOrganization.discountPerUserMonth}
          onChange={onLocalChange}
        />
        <Form.Dropdown
          label="Currency"
          id="currencyPerUserMonth"
          options={[
            { key: "sek", value: "sek", text: "SEK" },
            { key: "eur", value: "eur", text: "EUR" },
            { key: "nok", value: "nok", text: "NOK" },
          ]}
          value={localOrganization.currencyPerUserMonth}
          selection
          onChange={onLocalChange}
        />
        <Form.Button label="Action" content="Save" onClick={() => onChange(localOrganization)} />
      </Form.Group>
    </Form>
  );
}
